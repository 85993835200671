import { Container } from "@mui/material";
import Lottie from "lottie-react";
import loader from "../../assets/json/loader.json";

const Fallback = () => {
  return (
    <Container sx={{ maxHeight: "100vh" }} maxWidth={"true"}>
      <Lottie animationData={loader} />
    </Container>
  );
};

export default Fallback;
