import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
} from "@mui/material";
import { Markup } from "interweave";

export default function CollapsibleContainer({
  onClose = () => {},
  img_src,
  banner_text,
  btn_text,
  shouldOpen,
}) {
  return (
    <Collapse in={shouldOpen}>
      <Container
        maxWidth={"lg"}
        flex={1}
        sx={{
          backgroundImage: `url(${img_src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexdirection: "column",
          alignItems: "center",
          width: "100%",
          minHeight: "500px",
          border: "1px solid black",
        }}
      >
        <Card
          sx={{
            maxWidth: "40%",
            background: "rgba(255, 255, 255, 0.50)",
            borderRadius: "16px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
            webkitBackdropFilter: "blur(5px)",
            border: "1px solid rgba(255, 255, 255, 0.3)",
          }}
        >
          <CardContent>
            <Markup content={banner_text} />
            <Button
              variant={"contained"}
              sx={{
                my: 3,
                display: btn_text ? "inherit" : "none",
              }}
            >
              {btn_text}
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Collapse>
  );
}
