import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import bannerSlice from "./banners/bannerSlice";
import electionSlice from "./elections/electionSlice";
import eventSlice from "./events/eventSlice";
import jobSlice from "./jobs/jobSlice";
import lessonsSlice from "./lessons/lessonsSlice";
import questionSlice from "./lessons/questionsSlice";
import categoriesSlice from "./name/categoriesSlice";
import coursesSlice from "./name/coursesSlice";
import newsSlice from "./news/newsSlice";
import notificationSlice from "./notifications/notificationSlice";
import pressSlice from "./press/pressSlice";
import resourceSlice from "./resources/resourceSlice";
import authSlice from "./userAuth/authSlice";

export const store = configureStore({
  reducer: {
    resource: resourceSlice.reducer,
    job: jobSlice.reducer,
    banner: bannerSlice.reducer,
    news: newsSlice.reducer,
    events: eventSlice.reducer,
    press: pressSlice.reducer,
    auth: authSlice.reducer,
    notifications: notificationSlice.reducer,
    elections: electionSlice.reducer,
    questions: questionSlice.reducer,
    categories: categoriesSlice.reducer,
    courses: coursesSlice.reducer,
    lessons: lessonsSlice.reducer,
  },
  middleware: [thunk],
});
