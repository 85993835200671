import { GamlsLogo } from "../../assets";
import Image from "../image/image";

const imageStyle = {
  width: 177,
  height: 48,
  src: GamlsLogo,
  alt: "GAMLS Logo",
};

const Logo = () => {
  return <Image data={imageStyle} />;
};

export default Logo;
