import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  data: [],
};

export const electionSlice = createSlice({
  name: reducers.news,
  initialState,
  reducers: {
    saveElectionData: (state, action) => {
      state.data = action.payload;
    },
    updateData: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    clearData: (state) => {
      state.data = [];
    },
  },
});

export const { saveElectionData, clearData, updateData } = electionSlice.actions;

export default electionSlice;