import { Stack } from "@mui/material";
import { ChangeCircleOutlined } from "@mui/icons-material";

const CardHoverMenu = () => {
  return (
    <Stack
      flex={1}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      p={1}
    >
      Change Image
      <ChangeCircleOutlined />
    </Stack>
  );
};
export default CardHoverMenu;
