import {
  Slide,
  Avatar,
  DialogContent,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Dialog,
} from "@mui/material";
import React, { useCallback } from "react";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppDialog({ open, handleClose, children, title }) {
  const handleCloseClick = useCallback(() => {
    handleClose();
  }, [handleClose]);
  return (
    <Dialog
      open={open}
      onClose={handleCloseClick}
      TransitionComponent={Transition}
      fullWidth
      maxWidth={"lg"}
    >
      <AppBar
        sx={{ position: "relative", bgcolor: "white", color: "black" }}
        elevation={0}
      >
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            fontWeight={"bold"}
          >
            {title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseClick}
            aria-label="close"
          >
            <Avatar sx={{ backgroundColor: "#F30813" }}>
              <Close />
            </Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
