import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import Swal from "sweetalert2";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


const sendPasswordReset = async (email) => {
  await sendPasswordResetEmail(auth, email)
    .then(() =>
      Swal.fire({
        title: "Success",
        text: "Your password reset link has been sent to admin@gamls.org. Kindly check your email.",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#000000",
      })
    )
    .catch((e) =>
      Swal.fire({
        icon: "error",
        title: "Password reset error",
        confirmButtonColor: "#000000",
      })
    );
};

export { app, auth, db, storage, sendPasswordReset };
