import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  data: [],
};

export const categoriesSlice = createSlice({
  name: reducers.categories,
  initialState,
  reducers: {
    saveCategories: (state, action) => {
      state.data = action.payload;
    },
    updateCategories: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    clearCategories: (state) => {
      state.data = [];
    },
  },
});

export const { saveCategories, clearCategories, updateCategories } = categoriesSlice.actions;

export default categoriesSlice;
