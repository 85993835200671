import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { breadcrumbNameMap } from "../../constants/appConstants";
import Image from "../image/image";
import { Dashboard } from "../../assets";

function LinkRouter(props) {
  return (
    <Link
      {...props}
      component={RouterLink}
      sx={{ fontSize: 13, display: "flex", alignItems: "center" }}
    />
  );
}

function Breadcrumb({ pathnames }) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter underline="hover" color="inherit" to="/">
        <Image data={{ src: Dashboard, widht: 16, height: 16 }} />
        Dashboard
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <Typography color="text.primary" key={to} fontSize={13}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;
