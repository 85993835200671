import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  all_users: [],
  approved: [],
  pending: [],
  new: [],
};

export const authSlice = createSlice({
  name: reducers.user_auth,
  initialState,
  reducers: {
    saveData: (state, action) => {
      state.all_users = action.payload;
    },
    savePendingUsers: (state, action) => {
      state.pending = action.payload;
    },
    saveNewUsers: (state, action) => {
      state.new = action.payload;
    },
    saveApprovedUsers: (state, action) => {
      state.approved = action.payload;
    },
    clearData: (state) => {
      state.all_users = [];
      state.approved = [];
      state.pending = [];
      state.new = [];
    },
  },
});

export const {
  clearData,
  saveData,
  savePendingUsers,
  saveNewUsers,
  saveApprovedUsers,
} = authSlice.actions;

export default authSlice;
