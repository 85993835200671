import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  data: [],
};

export const eventSlice = createSlice({
  name: reducers.event,
  initialState,
  reducers: {
    saveData: (state, action) => {
      state.data = action.payload;
    },
    updateData: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    clearData: (state) => {
      state.data = [];
    },
  },
});

export const { saveData, clearData, updateData } = eventSlice.actions;

export default eventSlice;