import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/system";
import {
  Edit,
  FilterAlt,
  VisibilityOutlined,
  Delete,
  PersonAddAlt,
} from "@mui/icons-material";
import { actionIcons } from "../../constants/appConstants";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase-config";
import Swal from "sweetalert2";
import { Avatar } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    onRequestSort,
    headings,
    rowCount,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all resources",
            }}
          />
        </TableCell>
        {headings.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.name}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const getActionIcon = (iconName) => {
  switch (iconName) {
    case actionIcons.EDIT:
      return <Edit color={"primary"} />;
    case actionIcons.VIEW:
      return <VisibilityOutlined color={"primary"} />;
    case actionIcons.DELETE:
      return <Delete color={"primary"} />;
    case actionIcons.ADD_USER:
      return <PersonAddAlt color={"primary"} />;
    default:
      return <FilterAlt color={"primary"} />;
  }
};

function EnhancedTableToolbar(props) {
  const { numSelected, title, actions, selectedId } = props;
  const handleClick = React.useCallback((action = () => {}) => {
    action();
  }, []);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
          fontWeight={"bold"}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          {actions?.map((act, idx) => {
            return (
              <Tooltip
                key={act?.label}
                title={act?.label}
                sx={{ display: numSelected > 1 && "none" }}
              >
                <IconButton onClick={() => handleClick(act.action(selectedId))}>
                  {getActionIcon(act.id)}
                </IconButton>
              </Tooltip>
            );
          })}
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterAlt />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

const CustomTable = ({ data, onEdit = () => {} }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedId, setSelectedId] = React.useState("");

  React.useEffect(() => {}, [selected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = React.useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelected = data.items.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    },
    [data.items]
  );

  const handleClick = React.useCallback(
    (event, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
      setSelectedId(id);
    },
    [selected]
  );

  const handleChangePage = React.useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = React.useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.items.length) : 0;

  return (
    <Paper
      sx={{ width: "100%", mb: 2, backgroundColor: "#EAEAEA", mt: 3 }}
      elevation={0}
    >
      <EnhancedTableToolbar
        numSelected={selected.length}
        title={data.title}
        selectedId={selectedId}
        actions={
          data?.actions || [
            {
              id: actionIcons.EDIT,
              label: "Edit",
              action: () => {
                onEdit(selectedId);
              },
            },
            {
              id: actionIcons.DELETE,
              label: "Delete",
              action: async () => {
                Swal.fire({
                  title: "Do you want to delete the document?",
                  text: "If you delete this document, you won't be able to recover it later.",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#FD0113",
                  cancelButtonColor: "#000",
                  confirmButtonText: "Yes",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await deleteDoc(doc(db, data.path, selectedId))
                      .then(() => {
                        setSelected([]);
                        Swal.fire(
                          "Success!",
                          "Document is deleted",
                          "success",
                          false,
                          "#FD0113"
                        );
                      })
                      .catch((err) => {
                        Swal.fire(
                          "Something happened!",
                          "Document wasn't deleted. Kindly try again later.",
                          "error",
                          false,
                          "#FD0113"
                        );
                      });
                  }
                });
              },
            },
          ]
        }
      />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            headings={data.headings}
            rowCount={data.items.length}
          />
          <TableBody>
            {stableSort(data.items, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {data.headings.map(({ id }, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {id === "img_url" ? (
                            <Avatar src={row[id]} />
                          ) : (
                            row[id]
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CustomTable;
