import { Card, CardContent, Stack, Typography } from "@mui/material";
import TitleWithUnderline from "../titleWithUnderline/titleWithUnderline";

const DisplayCard = ({ title, min, value, percentage, period }) => {
  return (
    <Card elevation={0}>
      <CardContent
        p={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          bgcolor: "#F2F2F2",
          minHeight: 162,
          flex: 0.5,
        }}
      >
        <TitleWithUnderline title={title} />

        <Typography variant={"h6"} fontWeight={"bold"}>
          {value}
        </Typography>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={min ? "error.main" : "success.main"}>
            {percentage}
          </Typography>
          <Typography>{period}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DisplayCard;
