import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  lessons: [],
};

export const lessonsSlice = createSlice({
  name: reducers.lessons,
  initialState,
  reducers: {
    saveLessons: (state, action) => {
      state.lessons = action.payload;
    },
    updateLessons: (state, action) => {
      state.lessons = [...state.data, action.payload];
    },
    clearLessons: (state) => {
      state.lessons = [];
    },
  },
});

export const { saveLessons, clearLessons, updateLessons } =
  lessonsSlice.actions;

export default lessonsSlice;
