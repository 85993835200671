import { styled } from "@mui/material/styles";
import { dimens } from "../../constants/appConstants";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  height: dimens.appbar_height,
  backgroundColor: "white",
}));

export default DrawerHeader;
