import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "../../constants/appConstants";

const initialState = {
  payload: [],
};

export const questionSlice = createSlice({
  name: reducers.job,
  initialState,
  reducers: {
    saveQuestion: (state, action) => {
      state.payload = [...state.payload, action.payload];
    },
    clearQuestions: (state) => {
      state.payload = [];
    },
  },
});

export const { saveQuestion, clearQuestions } = questionSlice.actions;

export default questionSlice;
