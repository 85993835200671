import { Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { ErrorImg } from "../../assets";
import Image from "../image/image";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ color: "#FF0000" }}
            spacing={4}
          >
            <Typography variant="h5" fontWeight={"bold"} gutterBottom>
              Apologies
            </Typography>
            <Typography>Something happend while showing this page.</Typography>
            <Image
              data={{
                src: ErrorImg,
                height: 500,
              }}
            />
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={(e) => {
                e.preventDefault();
                window.location.reload();
              }}
            >
              Refresh Page
            </Button>
          </Stack>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
