import { Box } from "@mui/material";

const Image = ({ data }) => {
  return (
    <Box
      component="img"
      sx={{
        height: data.height,
        width: data.width,
        maxHeight: { xs: data.mh_s, md: data.mh_m },
        maxWidth: { xs: data.mw_s, md: data.mw_m },
      }}
      alt={data.alt}
      src={data.src}
    />
  );
};
export default Image;
